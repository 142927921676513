import request from "@/utils/request"

//后台-专家服务数据一览
export function getCompanySpecialistList(data) {
    return request({
      url: '/serverSpecialist/getCompanySpecialistList',
      method: 'post',
      data
    })
  }
//单个数据专家详情
export function getById(query) {
    return request({
      url: '/serverSpecialist/getById',
      method: 'get',
      params:query
    })
  }
//新增专家
export function save(data) {
    return request({
      url: '/serverSpecialist/insert',
      method: 'post',
      data
    })
  }
//编辑专家
export function update(data) {
    return request({
      url: '/serverSpecialist/update',
      method: 'post',
      data
    })
  }
  //自动匹配
  export function selectMatching(data) {
    return request({
      url: '/serverSpecialist/selectMatching',
      method: 'post',
      data
    })
  }
  //加入已选
  export function insertSelectedCompany(data) {
    return request({
      url: '/serverSpecialist/insertSelectedCompany',
      method: 'post',
      data
    })
  }
//已经匹配
export function selectedCompanyPage(data) {
    return request({
      url: '/serverSpecialist/selectedCompanyPage',
      method: 'post',
      data
    })
  }
  //发布已选
  export function releaseCompany(data) {
    return request({
      url: '/serverSpecialist/releaseCompany',
      method: 'post',
      data
    })
  }
  //移出已选
  export function deleteCorrelation(data) {
    return request({
      url: '/serverSpecialist/deleteCorrelation',
      method: 'post',
      data
    })
  }
  //新增专家标签
  export function savelabel(data) {
    return request({
      url: '/serverSpecialist/insertActivityLabel',
      method: 'post',
      data
    })
  }
  //编辑专家标签
  export function updateInformationLabel(data) {
    return request({
      url: '/serverSpecialist/updateActivityLabel',
      method: 'post',
      data
    })
  }
  //专家详情
  export function getBylabelId(query) {
    return request({
      url: '/serverSpecialist/getBySpecialistLabelId',
      method: 'get',
      params:query
    })
  }
  //咨询用户数据一览
  export function selectConsultingUser(data) {
    return request({
      url: '/serverSpecialist/selectConsultingUser',
      method: 'post',
      data
    })
  }
  //运营人员修改用户联系专家的状态
  export function concatUser(query) {
    return request({
      url: '/serverSpecialist/concatUser',
      method: 'get',
      params:query
    })
  }
  //批量设置专家类型---通用版
  export function updateGeneralVersion(data) {
    return request({
      url: '/serverSpecialist/updateGeneralVersion',
      method: 'post',
      data
    })
  }
//解冻专家
export function cancelFreezeSpecialist(query) {
  return request({
    url: '/serverSpecialist/cancelFreezeSpecialist',
    method: 'get',
    params:query
  })
}
 //冻结专家
 export function freezeSpecialist(query) {
  return request({
    url: '/serverSpecialist/freezeSpecialist',
    method: 'get',
    params:query
  })
}
//专家查看一览查询
export function listUserCheck(data) {
  return request({
    url: '/serverSpecialist/listUserCheck',
    method: 'post',
    data
  })
}
//专家分享一览查询
export function listUserShare(data) {
  return request({
    url: '/serverSpecialist/listUserShare',
    method: 'post',
    data
  })
}

//已推送企业数据一览-专家
export function pushedCompanyPageSpecialist(data) {
  return request({
    url: '/serverSpecialist/pushedCompanyPageSpecialist',
    method: 'post',
    data
  })
}
//专家配置智参中心数据一览
export function wisdomGinsengPage(data) {
  return request({
    url: '/serverSpecialist/wisdomGinsengSpecialistPage',
    method: 'post',
    data
  })
}
//专家配置智参中心上架
export function wisdomGinsengOnSelf(data) {
  return request({
    url: '/serverSpecialist/wisdomGinsengOnSelf',
    method: 'post',
    data
  })
}
//专家配置智参中心下架
export function wisdomGinsengOffSelf(data) {
  return request({
    url: '/serverSpecialist/wisdomGinsengOffSelf',
    method: 'post',
    data
  })
}
//批量设置专家下架
export function updateOffShelf(data) {
  return request({
    url: '/serverSpecialist/updateOffShelf',
    method: 'post',
    data
  })
}
//批量设置专家上架
export function updateOnShelf(data) {
  return request({
    url: '/serverSpecialist/updateOnShelf',
    method: 'post',
    data
  })
}
//批量设置专家类型---通用版 (配置智参中心)
export function updateWisdomGinseng(data) {
  return request({
    url: '/serverSpecialist/updateWisdomGinseng',
    method: 'post',
    data
  })
}
//手动配置企业
export function artificialSelectMatching(data) {
  return request({
    url: '/serverSpecialist/artificialSelectMatching',
    method: 'post',
    data
  })
}
// 专家名称模糊查询 /getSystemSpecialistParam
export function getSystemSpecialistParam(data) {
  return request({
    url: '/serverSpecialist/getSystemSpecialistParam',
    method: 'get',
    params: data
  })
}



  






