<template>
  <div>
    <el-card style="margin-bottom: 20px" class="top_card">
      <div class="card_flex">
        <div class="storeCenter_item_top_left">
          <div class="banner_box">
            <img :src="topDetails.headPortrait" alt="" />
          </div>
          <div class="storeCenter_item_top_left_flex">
            <div style="display: flex; align-items: center">
              <h5
                style="
                  display: inline-block;
                  margin: 0;
                  font-size: 18px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;

                  -webkit-box-orient: vertical;
                "
              >
                {{ topDetails.name }}
              </h5>

              <span class="generalVersion" v-if="topDetails.generalVersion == 0">通用版</span>
              <span class="generalVersion" v-if="topDetails.generalVersion == 1">个性版</span>
              <div class="mark_box">
                <div v-if="topDetails.specialistType == 1">
                  <img src="../../../assets/image/Group 3298.png" alt="" /><span>平台专家</span>
                </div>
                <div v-else>
                  <img src="../../../assets/image/展厅-5 3.png" alt="" /><span>渠道专家</span>
                </div>

                <!-- <div>
                  <img src="../../../assets/image/Vector-1.png" alt="" /><span
                    >{{ topDetails.consultingFee }}/小时</span
                  >
                </div> -->
              </div>
            </div>
            <div class="storeCenter_item_top_left_flex_tag">
              <span style="display: flex" v-if="topDetails.tagsList">
                <span v-for="(o, index) in topDetails.tagsList" :key="index">
                  <span v-if="index < 3" class="office">{{ o }}</span>
                </span>
              </span>

              <el-popover placement="right" width="430" trigger="hover">
                <div
                  class="hidden_label_box"
                  style="
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-content: center;
                    flex-wrap: wrap;
                  "
                >
                  <span
                    class="office"
                    style="
                      border: 1px solid #559eff;
                      color: #4e93fb;
                      box-sizing: border-box;
                      padding: 1px 6px;
                      display: block;
                      font-size: 14px;
                      font-weight: 400;
                      border-radius: 4px;
                      margin-right: 10px;
                      margin-bottom: 10px;
                    "
                    v-for="(o, index) in topDetails.tagsList"
                    :key="index"
                    >{{ o }}</span
                  >
                </div>
                <span
                  class="office"
                  slot="reference"
                  v-if="topDetails.tagsList && topDetails.tagsList.length > 4"
                  >{{ topDetails.tagsList.length }}+</span
                >
              </el-popover>
            </div>
            <div>{{ topDetails.sponsor }}</div>
            <div>所在地区：{{ topDetails.province }}-{{ topDetails.city }}</div>
            <div class="address">
                <div class="address_flex" v-if="topDetails.localOriginalPrice">
                  
                  <span> 本地约见：</span>

                  <img width="15" height="15" src="../../../assets/image/Vector-1.png" alt="" />

                  <span style="margin-left: 5px;">{{ topDetails.localOriginalPrice }}</span>
                </div>
                <div class="address_flex" v-if="topDetails.originalPrice">
                  <span> 线上会议：</span>

                  <img width="15" height="15" src="../../../assets/image/Vector-1.png" alt="" />

                  <span style="margin-left: 5px;">{{ topDetails.originalPrice }}</span>
                </div>
            
            </div>
          </div>
        </div>
        <div class="right">
          <div class="total_num">
            <span>{{ total }}</span>
          </div>
          <div>
            <span>约聊人数</span>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="bottom_card">
      <div class="seach_box">
        <el-input
          placeholder="请输入企业名称"
          v-model="queryInfo.queryConditions"
          class="input-with-select"
        >
          <el-button class="seach" slot="append" @click="search()">搜索</el-button>
        </el-input>
        <!-- <el-checkbox v-model="checked" style="margin-left: 10px" @change="checkGeneralVersion"
            >全选所有智参企业</el-checkbox
          > -->
      </div>
      <el-table
        :header-cell-style="getRowClass"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column label="编号" width="120" align="center" prop="id"> </el-table-column>

        <el-table-column prop="headImgUrl" label="微信头像" align="center" show-overflow-tooltip>
          <template v-slot="{ row }">
            <el-popover placement="right" trigger="hover" width="40">
              <el-image :src="row.headImgUrl" fit="fit"></el-image>
              <el-image
                slot="reference"
                :src="row.headImgUrl"
                style="width: 40px; height: 40px; border-radius: 5px"
                fit="fit"
              ></el-image>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="姓名" align="center" show-overflow-tooltip>
        </el-table-column>

        <el-table-column prop="position" label="职位" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" align="center" show-overflow-tooltip>
        </el-table-column>

        <el-table-column prop="createTime" label="用户等级" align="center" width="100">
          <template slot-scope="{ row }">
            <span v-if="row.userLevel == 0">普通用户</span>
            <span v-if="row.userLevel == 1">个人会员</span>
            <span v-if="row.userLevel == 2">企业会员</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="companyFullName"
          label="企业全称"
          align="center"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="chatType" label="约聊方式" align="center" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="createTime" label="所在城市" align="center" width="100">
          <template slot-scope="{ row }">
            {{ row.city }}
          </template>
        </el-table-column>
        <el-table-column prop="activePrice" label="支付金额" align="center" >
        </el-table-column>

        <el-table-column width="180" sortable prop="createTime" label="约聊时间" align="center" >
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="200px"
        >
          <template slot-scope="scope">
            <el-button v-if="scope.row.contactStatus" style="color: #999999" type="text"
              >已联系
            </el-button>

            <el-button v-else style="color: cornflowerblue" type="text" @click="torecord(scope.row)"
              >未联系
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          style="margin-top: 20px; text-align: center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getById, concatUser, selectConsultingUser } from '../../../api/expert.js'
export default {
  //import引入的组件需要注入到对象中才能使用
  data() {
    //这里存放数据
    return {
      checked: false,
      radio: null,
      showactivityIntroL: '',
      showactivityIntroLBol: false,
      queryInfo: {
        id: null,
        orderByParam:'t.create_time DESC',
        pageNum: 1,
        pageSize: 10,
        queryConditions: null,
        status: null
      },
      activityId: this.$route.query.id,
      topDetails: {},
      tableData: [],
      companyIds: [],
      total: 0
    }
  },

  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},

  created() {
    this.search()
    // this.loading = true
    getById({ id: this.activityId }).then((res) => {
      if (res.data.resultCode == 200) {
        console.log(res.data.data.list, 'dfsd')
        this.topDetails = res.data.data
        this.showactivityIntroL = this.topDetails.specialistIntro.slice(0, 60) + '...'
      }
    })
  },

  mounted() {},
  //方法集合
  methods: {
    async torecord(row) {
      const res = await concatUser({ id: row.userConsultId })
      if (res.data.resultCode == 200) {
        this.$message.success('联系成功！')
        this.search()
      }
    },
    // 点击头部table栏
    clickItem(item) {
      this.headTabActive = item.id
      // console.log(item.id)
    },
    search() {
      this.queryInfo.id = this.activityId
      selectConsultingUser(this.queryInfo).then(({ data: res }) => {
        this.tableData = res.data.list
        this.total = res.data.total
        console.log(res, 'selectMatchingActivity')
      })
    },
    checkGeneralVersion(bol) {
      if (bol) {
        this.queryInfo.generalVersion = 0
      } else {
        this.queryInfo.generalVersion = ''
      }
      this.search()
    },

    // 通过函数给table表头设置背景色
    getRowClass({ rowIndex, columnIndex }) {
      if (rowIndex == 0) {
        return 'background:#f7f8fa;'
      }
    },
    // 排序类型：默认1：投稿时间；2：操作时间；历史记录中操作时间是1
    // 表格的排序发生改变时
    handleSortChange(val) {
      if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orderByParam = 't.create_time ASC'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orderByParam = 't.create_time DESC'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orderByParam = 't.create_time DESC'
          this.search()
        }
      }
      //  console.log(column, prop, order, 'olumn, prop, order')
    },
    // table的checkbox状态改变时触发
    handleSelectionChange(val) {
      this.companyIds = val.map((el) => el.id)
    },
    // 切换单页展示数据数量
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.queryInfo.pageSize = val
      this.search()
    },
    // 切换分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.queryInfo.pageNum = val
      this.search()
    }
  }
}
</script>
<style lang="less" scoped>
.top_card {
  ::v-deep .el-card__body {
    // padding: 100px;
    display: flex;
  }
  .card_flex {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: left;
    width: 100%;
    position: relative;
    .right {
      position: absolute;
      right: 100px;
      display: flex;
      flex-direction: column;
      justify-content: right;
      align-items: center;
      .total_num {
        margin-bottom: 10px;
        font-size: 30px;
        font-weight: 550;
        color: #ff7d18;
      }
    }
  }

  // display: flex;
  .storeCenter_item_top_left {
    display: flex;
    flex-direction: row;
    height: 107.73px;

    .banner_box {
      position: relative;
      width: 103.55px;
      height: 103.73px;
      padding-right: 20px;
      img {
        width: 103.55px;
        height: 103.73px;
        border-radius: 4px;
        margin-right: 14px;
      }
      .activity_state {
        position: absolute;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 34px;
        text-align: center;
        color: #ffffff;
        top: -10px;
        left: -9px;
        width: 78px;
        height: 34px;
        border-radius: 5px 15px 15px 0;
      }
      .not_started {
        background: #4e93fb;
      }
      .have_in_hand {
        background: #ff7d18;
      }
      .closed {
        background: #999999;
      }
    }

    .storeCenter_item_top_left_flex {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .generalVersion {
        background: #448aff;
        border-radius: 2px;
        padding: 5px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        /* identical to box height */
        margin: 0 10px;
        color: #ffffff;
      }
      .mark_box {
        display: flex;
        img {
          width: 14px;
          // height: 14px;
          margin-right: 6px;
          color: #6e6e6e;
        }
        & > div {
          margin-left: 10px;
        }
      }
      .img_commer {
        display: flex;
        border: 1px solid #146aff;
        border-radius: 39px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #146aff;
        }
        .color_img {
          background: #146aff;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }
      .img_commers {
        display: flex;
        border: 1px solid #ff9c00;
        border-radius: 39px;
        margin: 0px 10px;
        .name {
          font-size: 12px;
          line-height: 20px;
          color: #ff9c00;
        }
        .color_img {
          background: #ff9c00;
          height: 20px;
          width: 25px;
          border-radius: 20px;
          position: relative;
          left: -10px;
          img {
            width: 13px;
            height: 13px;
            position: relative;
            left: 5px;
            top: 2px;
          }
        }
      }

      h4 {
        color: #1f2d3d;
        font-size: 26px;
        font-weight: 500;
        margin: 0;
      }

      .storeCenter_item_top_left_flex_tag {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: center;
        padding-top: 10px;
        margin-bottom: 1px;
        width: 500px;
        span {
          box-sizing: border-box;
          display: block;
          font-size: 14px;
          font-weight: 400;
          border-radius: 4px;
        }

        .office {
          border: 1px solid #559eff;
          color: #4e93fb;
          padding: 1px 6px;
          margin-right: 10px;
        }

        .support {
          border: 1px solid #fd523f;
          color: #fd523f;
          margin-left: 12px;
        }
      }

      .address {
       
        display: flex;
        .address_flex{
          display: flex;
          align-items: center;
        }
       
      }
    }
  }
  .right {
    flex: 1;
    .right_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .activityName {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: bold;
        font-size: 20px;

        color: #333333;
      }
    }
    .label_list {
      span {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #4e93fb;
        margin-right: 10px;
      }
    }
    .synopsis_box {
      margin-top: 15px;
      padding: 15px;
      // width: 1310.83px;
      // height: 65px;
      // left: 538.91px;
      // top: 272.28px;

      background: #f7f7f7;
      display: flex;
      .synopsis {
        flex-shrink: 0;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #595959;
      }
      .synopsis_content {
        flex: 1;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #333333;

        .more {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #4e93fb;
        }
      }
    }
  }
}
.bottom_card {
  .relevance_box {
    display: flex;
    justify-content: space-between;
    .relevance {
    }
    .right {
      img {
        width: 20px;
        vertical-align: middle;
        margin-right: 5px;
      }
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      // line-height: 20px;
      color: #595959;
    }
  }

  .seach_box {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    justify-content: right;
    .input-with-select {
      width: 400px;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }
  .office {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #4e93fb;
    margin-right: 10px;
  }
}
</style>
